@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --btn-text-case: capitalize;
}

.tabs {
  @apply gap-x-2;

  .tab {
    @apply pb-10 px-3 pt-3 font-medium;
    &:not(.tab-active) {
      @apply border-0 opacity-50 hover:opacity-100;
    }
  }

  .tab-active {
    @apply text-primary border-primary;
  }
}
// TODO(ladaj): temporal untill UI-KIT implementation
.btn {
  @apply h-10 min-h-0;
}

.table thead tr th {
  @apply bg-base-100;
}
.table-zebra tbody tr:nth-child(even) th,
.table-zebra tbody tr:nth-child(even) td {
  @apply bg-[#F6F8FA];
}

.table {
  th,
  td {
    @apply text-xs whitespace-normal py-2 px-0;
  }

  th {
    @apply capitalize;
    overflow-wrap: anywhere;
  }

  td:empty,
  td > *:empty:not(input) {
    &::before {
      content: '-';
    }
    // @apply text-center;
  }
}
.table {
  &.table-without-empty-dashes {
    td:empty,
    td > *:empty {
      &::before {
        content: '';
      }
    }
  }
}

.badge {
  @apply rounded-md;
}
