@use '@angular/material' as mat;
@import '@angular/cdk/overlay-prebuilt.css';
// @import 'ngx-toastr/toastr';
.toast-container .ngx-toastr {
  @apply w-[24rem];
  // @apply flex flex-row flex-wrap;
}
// .toast-close-button {
//   order: 2;
//   position: static;
// }
// .toast-title {
//   @apply flex-1;
// }
// .toast-message {
//   @apply basis-10/12;
// }
.toast-info {
  @apply bg-blue-600;

  background-image: url('data:image/svg+xml,"<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" style="width:var(--ng-icon__size, 1em);height:var(--ng-icon__size, 1em);stroke-width:var(--ng-icon__stroke-width, 1.5)"><path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"/></svg>";');
}
.toast-error {
  @apply bg-red-600;

  background-image: url('data:image/svg+xml,"<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" style="width:var(--ng-icon__size, 1em);height:var(--ng-icon__size, 1em);stroke-width:var(--ng-icon__stroke-width, 1.5)"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/></svg>";');
}
.toast-success {
  @apply bg-emerald-600;

  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" aria-hidden="true" style="width:var(--ng-icon__size, 1em);height:var(--ng-icon__size, 1em);stroke-width:var(--ng-icon__stroke-width, 1.5)"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/></svg>');
}
.toast-warning {
  @apply bg-yellow-500;

  background-image: url('data:image/svg+xml,"<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" style="width:var(--ng-icon__size, 1em);height:var(--ng-icon__size, 1em);stroke-width:var(--ng-icon__stroke-width, 1.5)"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/></svg>";');
}

@include mat.all-component-typographies();
@include mat.core();

$navix-primary: (
  50: #e2e8f1,
  100: #b6c6dd,
  200: #86a1c7,
  300: #557bb0,
  400: #305e9f,
  500: #0c428e,
  600: #0a3c86,
  700: #08337b,
  800: #062b71,
  900: #031d5f,
  A100: #90a8ff,
  A200: #5d80ff,
  A400: #2a58ff,
  A700: #1044ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff
  )
);

$navix-app-primary: mat.define-palette($navix-primary);
$navix-app-accent: mat.define-palette($navix-primary);
$navix-app-warn: mat.define-palette(mat.$red-palette);
$navix-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $navix-app-primary,
      accent: $navix-app-accent,
      warn: $navix-app-warn
    )
  )
);
@include mat.all-component-themes($navix-app-theme);
